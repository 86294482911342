$andes-theme: 'mercadolibre';

// Andes components
@import '~@andes/card/index';

// Commons
@import '~@andes/common/index';

// Error Boundary
@import '../ErrorBoundary/styles.scss';

body {
  background: $andes-bg-primary;
}

a,
a:link,
a:visited {
  text-decoration: none;
}

.categories-container {
  color: $andes-gray-550;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px 32px;

  &__main {
    display: inline-flex;
    width: 100%;
  }

  &__title {
    color: #4a4a4a;
    font-size: 24px;
    font-weight: 400;
    margin: 24px auto;
    padding: 0;
  }
}

.categories {
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
  padding: 32px 40px 40px;
  width: 100%;

  &__container {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;

      .categories__list {
        padding-bottom: 0;
      }
    }
  }

  &__subtitle-title {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }

  &__title {
    color: $andes-gray-900;
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 8px;

    a:hover {
      color: $andes-blue-500;
    }
  }

  &__list {
    column-count: 4;
    column-gap: 15px;
    margin: 0 auto;
    padding-bottom: 40px;
    padding-left: 0;
  }

  &__container:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }

  &__item {
    float: left\9;
    font-size: 14px;
    line-height: 24px;
    list-style-type: none;
    width: 25%\9;

    a {
      color: rgb(0 0 0 / 45%);

      &:hover {
        color: $andes-blue-500;
      }
    }
  }
}

/* IE 9 only */
@media screen and (width >= 0\0) and (resolution >= 0.001dpcm) {
  .categories__item {
    float: left\9;
    width: 25%\9;
  }
}
